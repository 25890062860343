<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="bg"></div>
      <img class="logo" src="../assets/img/logo.png"/>
      <div class="ms-title">华盛科技 贡值值管理系统</div>
      <div class="form Mobile">
        <div class="img">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/AdminUser.png">
        </div>
        <div class="set">
          <input placeholder="请输入手机号码" v-model="param.UserName">
        </div>
      </div>

      <div class="form Paws">
        <div class="img">
          <img src="https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/AdminPaws.png">
        </div>
        <div class="set">
          <input placeholder="请输入密码" v-model="param.Password" type="password">
        </div>
      </div>

      <div class="button" @click="submitForm()">登陆</div>
    </div>
  </div>
</template>

<script>
import {fetchData} from "../api/Login";
import {roleData} from "../api/Sidebar";

export default {
  data() {
    return {
      param: {
        UserName: "",
        Password: ""
      },

      rules: {
        UserName: [
          {required: true, message: "请输入用户名", trigger: "blur"}
        ],
        Password: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ]
      }
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm() {
      if (this.param.UserName && this.param.Password) {
        fetchData({...this.param}).then(res => {
          if (res.code == 0) {
            let sum = res.sum
            let EffTime = res.EffTime

            localStorage.setItem("EffTime", EffTime);
            this.$store.state.UserInfo = sum
            this.$store.commit('UserInfoTitle')
            let Admin_id = sum.Admin_id
            localStorage.setItem("User_id", Admin_id);
            this.getrole()
            this.$message.success(res.message);
            setTimeout(() => {
              this.$router.push("/");
            }, 1000)
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error("缺少必填项");
      }

    },
    getrole() {
      let UserInfo = this.$store.state.UserInfo
      let Jurisdiction = UserInfo.Jurisdiction

      if (Jurisdiction) {
        roleData({Jurisdiction}).then(res => {
          if (res.code == 0) {
            let RoleRouter = res.RoleRouter
            let f = []
            for (let item in RoleRouter) {
              if (RoleRouter[item].subs) {
                if (RoleRouter[item].remarks == '操作和功能') {
                  f.push({
                    index: RoleRouter[item].path,
                    title: RoleRouter[item].label
                  })
                } else {
                  let children = RoleRouter[item].children
                  let sub = []
                  for (let item in children) {
                    if (children[item].subs) {
                      sub.push({
                        index: children[item].path,
                        title: children[item].label
                      })
                    }
                  }
                  f.push({
                    index: RoleRouter[item].path,
                    title: RoleRouter[item].label,
                    subs: sub
                  })
                }
              }
            }
            this.RoleRouter = f
          } else {
            this.$message.error(res.message);
            setTimeout(() => {
              this.$message.error('即将退出后台');
            }, 500)
            setTimeout(() => {
              localStorage.removeItem("User_id");
              localStorage.removeItem("EffTime");
              this.$router.push("/");
            }, 3000)
          }
        })
      }
    },
  }
};
</script>

<style scoped lang="scss">

.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-color: #ffffff;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 800px;
  height: 450px;
  margin: -225px 0 0 -400px;
  border-radius: 5px;
  background: rgba(255, 255, 255);
  overflow: hidden;

  .bg {
    background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/Adminbg.png");
    background-size: 100% 100%;
    width: 380px;
    height: 308px;
    position: absolute;
    left: 0;
    top: 74px;
  }

  .logo {
    //background-image: url("https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2021-4-30/Login/titleImg.png");
    //background-size: 100% 100%;
    width: 100px;
    height: 100px;
    position: absolute;
    left: 384px;
    top: 40px;
  }


  .ms-title {
    font-size: 16px;
    color: #000;
    position: absolute;
    left: 475px;
    top: 74px;
    font-weight: 200;
  }

  .Mobile {
    top: 170px;
  }

  .Paws {
    top: 250px;
  }

  .form {
    width: 370px;
    height: 50px;
    position: absolute;
    left: 400px;

    .img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 11px;
      top: 9px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .set {
      width: 310px;
      height: 50px;
      position: absolute;
      right: 0;

      input {
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        outline: none;
        text-indent: 1rem;
        border-bottom: 1px solid #dededf;
      }

      input:focus {
        border: none;
        border-bottom: 1px solid #666666;
      }
    }
  }

  .button {
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    position: absolute;
    right: 50px;
    top: 335px;
    background-color: #ff725e;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}


//.black {
//  display: inline;
//  color: #000000;
//}
//

//


//.ms-content {
//  padding: 30px 30px;
//}
//
//.login-btn {
//  text-align: center;
//}
//
//.login-btn button {
//  width: 100%;
//  height: 36px;
//  margin-bottom: 10px;
//}
//
//.login-tips {
//  font-size: 12px;
//  line-height: 30px;
//  color: #fff;
//}
</style>
